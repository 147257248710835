import config from "./config"

export const getEnvironment = () => {
    let list;
    let add;
    let remove;
    let awsUrl;

    if (config.state.environment == "prod") {
        list = config.environments.prod.endpoints.list;
        add = config.environments.prod.endpoints.add;
        remove = config.environments.prod.endpoints.delete;
        awsUrl = 'https://web-resource-manager.hta.services/'
    } else {
        list = config.environments.dev.endpoints.list;
        add = config.environments.dev.endpoints.add;
        remove = config.environments.dev.endpoints.delete;
        awsUrl = 'http://hta-test.s3-us-east-2.amazonaws.com/'
    }

    return {
        list,
        add,
        remove,
        awsUrl
    }
}
