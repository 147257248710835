/**
 * Content loader
 */
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const ContentLoader = () => (
   <div style={{ alignItems: "center", display: "flex", justifyContent: "center", height: "100vh", width: "100vw" }}>
      <CircularProgress variant="indeterminate" color="inherit"/>
   </div>
);

export default ContentLoader;