import { Typography, Button, InputAdornment, OutlinedInput } from "@mui/material";
import React, { useState, useEffect, Fragment } from "react";
import TextField from '@mui/material/TextField';
import { lightGreen, red } from "@mui/material/colors";
import { withStyles, makeStyles } from "@mui/styles"
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import { addResource, getResources } from "./ResourcesService";
import { useLocation, useHistory } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/lab/Alert';
import ContentLoader from './ContentLoader';


const SaveButton = withStyles((theme) => ({
    root: {
        color: 'white',
        backgroundColor: lightGreen[500],
        "&:hover": {
            backgroundColor: lightGreen[700],
        },
        textTransform: 'capitalize',
        height: '25px',
        width: '56px'
    },
}))(Button);

const CancelButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText('#dc3545'),
        backgroundColor: '#dc3545',
        "&:hover": {
            backgroundColor: '#e8172b',
        },
        textTransform: 'capitalize',
        height: '25px',
        width: '56px'
    },
}))(Button);

const useStyles = makeStyles((theme) => ({
    error: {
        marginLeft: 40,
        marginTop: -20,
        fontSize: 13,
        fontSize: 13,
        color: 'red',
        textAlign: 'right',
        marginRight: 26
    }
}));

function AddResource() {
    const location = useLocation()
    var params = location.state.params;
    var token = location.state.token;
    var bucket = location.state.bucket;
    var prefix = location.state.prefix;
    const addNewResourceSchema = Yup.object({
        title: Yup.string().required('Required').max(250, 'Must be 250 characters o less'),
        description: Yup.string().max(500, 'Must be 500 characters o less'),
        tags: Yup.string().max(500, 'Must be 500 characters o less').matches('([^,]+)', 'Invalid format'),
        // file: Yup.mixed().required("A file is required").test('fileFormat', 'PDF only', (value) => {
        //     return value && ['application/pdf'].includes(value.type);
        // })
    })

    const classes = useStyles();

    const [selectedFile, setFile] = useState(undefined);
    const [showError, setShowError] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [errorFile, setErrorFile] = useState("");
    const [fileList, setFileList] = useState();

    const handleUpload = (event) => {
        let file = event.target.files[0];
        let supportedFiles = [
            "video/mp4",
            "application/pdf",
            "video/mpeg",
            "application/vnd.ms-powerpoint",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "audio/mpeg",
            "audio/wav"
        ];
        if (file !== undefined && !supportedFiles.includes(file.type)) {
            setErrorFile("Only support pdf");
        } else if (file.size >= 6000000){
            setErrorFile("File size limit Exceeded. Please upload files below the 6 Megabyte limit.");
        } else {
            setErrorFile("");
            getResources(token, prefix, bucket).then(data => {
                setFileList(data);
            }).catch(err => {
                console.log(err);
            });
            setFile(file);
        }


    };

    const addNewResource = (event) => {
        let fileAlreadyExists = false;
        if(fileList.length > 0){
            for(let file of fileList){
                if(file.Title === event.title){
                    fileAlreadyExists = true;
                }
            }
        }

        if (selectedFile === undefined) {
            setErrorFile("Upload failed check the error.");
            setUploading(false);
            setShowError(true);
            return false;
        } else if (fileAlreadyExists === true){
            setErrorFile("File name already exists.");
            setUploading(false);
            setShowError(true);
            return false;
        } else {
            var resource = { title: event.title, description: event.description, tags: event.tags, file: selectedFile };
            setUploading(true);
            addResource(token, prefix, bucket, resource).then(() => {
                history.push("/?token=" + token + '&bucket=' + bucket + '&prefix=' + prefix);
            }).catch(err => {
                console.log(err);
                setUploading(false);
                setShowError(true);
            });
        }

        
    }
    const history = useHistory();
    const handleCancel = () => {
        history.push("/?token=" + token + '&bucket=' + bucket + '&prefix=' + prefix);
    }

    const handleClose = (event, reason) => {
        setShowError(false);
    };

    const initialValues = {
        title: '',
        description: '',
        tags: '',
        file: undefined
    }
    return (
        <div>
            {uploading == true ?
                <ContentLoader />
                :
                <div>
                    <Snackbar open={showError} autoHideDuration={5000} className="snackBar" onClose={handleClose}>
                        <Alert severity="error" onClose={handleClose}>
                            There was an error. Please try again
                        </Alert>
                    </Snackbar>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={addNewResource}
                        validationSchema={addNewResourceSchema}
                    >
                        {({ values,
                            touched,
                            errors,
                            dirty,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            isValid }) => (
                            <Form>
                                <Grid container xs={12}>
                                    <Grid container xs={12} justify="flex-end">
                                        <Box mr={3} mt={3}>
                                            <Typography variant="h6" size="small" style={{ marginRight: 20 }}>
                                                Add new Resource File
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box ml={3}>
                                            <Typography>
                                                Title
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box p={3}>
                                            <TextField
                                                required
                                                variant="outlined"
                                                size="small"
                                                className="inputBox"
                                                name="title"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </Box>
                                        <ErrorMessage name="title" component="div" className={classes.error} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box ml={3}>
                                            <Typography>
                                                Description
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box p={3}>
                                            <TextField
                                                multiline
                                                rows={4}
                                                variant="outlined"
                                                className="inputBox"
                                                name="description"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Enter a description"
                                            />
                                        </Box>
                                        <ErrorMessage name="description" component="div" className={classes.error} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box ml={3}>
                                            <Typography>
                                                Tags
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box p={3}>
                                            <TextField
                                                variant="outlined"
                                                className="inputBox"
                                                size="small"
                                                name="tags"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Add one or more tags here, separated by commas" />
                                        </Box>
                                        <ErrorMessage name="tags" component="div" className={classes.error} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box p={3}>
                                            <FormGroup>
                                                <FormLabel>File Upload</FormLabel>
                                                <OutlinedInput
                                                    required
                                                    name="file"
                                                    fullWidth
                                                    type="file"
                                                    placeholder="Upload Resource File"
                                                    endAdornment={<InputAdornment position="end">Browse</InputAdornment>}
                                                    onChange={handleUpload}
                                                    onBlur={handleBlur}
                                                />
                                            </FormGroup>
                                        </Box>
                                        <div className={classes.error}>
                                                {errorFile}    
                                        </div>
                                    </Grid>
                                    <Grid container xs={12} spacing={2} justify="flex-end" style={{ marginRight: 20 }}>
                                        <Grid item>
                                            <Box mt={3}>
                                                <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <Box mt={3}>
                                                <SaveButton type="submit">Save</SaveButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )
                        }
                    </Formik>
                </div>
            }
        </div>
    );
}

export default AddResource;