import React, { useState, useEffect, Fragment } from "react";
import { Grid, Button, FormControl, InputLabel, Select } from '@mui/material';
import { withStyles, makeStyles } from "@mui/styles"
import { getResources, deleteResource } from "./ResourcesService";
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/styles';
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import { Link } from "react-router-dom"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import _ from 'lodash';
import ContentLoader from './ContentLoader';
import DataLoader from './DataLoader';
import moment from 'moment';
// import SearchBar from "material-ui-search-bar";
import InputBase from '@mui/material/InputBase';
import Tooltip from '@mui/material/Tooltip';
import { useHistory } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import {
    getEnvironment
} from './environment';

var environment = getEnvironment();

const NewResourceButton = styled(Button)({
    color: 'white',
    backgroundColor: '#D78825',
    "&:hover": {
        backgroundColor: '#D78825',
    },
    borderRadius: '16px',
    textTransform: 'capitalize',
    textDecoration: 'none',
    padding: '10px',
    fontSize: '12px'
});


const DeleteButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#dc3545'),
    backgroundColor: '#dc3545',
    "&:hover": {
        backgroundColor: '#e8172b',
    },
    textTransform: 'capitalize',
    height: '25px',
    width: '56px'
}));

const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 12,
    },
  }))(Tooltip);

const useStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
    formControl: {
        minWidth: 120,
        height: '28px'  
    },
    margin: {
        margin: theme.spacing(1),
    },
    newButton: {
        height: '32px',
        color: 'white',
        backgroundColor: '#D78825',
        "&:hover": {
            backgroundColor: '#D78825',
        },
        borderRadius: '16px',
        textTransform: 'capitalize',
        textDecoration: 'none',
        padding: '10px',
        fontSize: '12px'
    },
    marginButton: {
        marginTop: '20px',
        marginBottom: '20px'
    },
    searchInput: {
        height: '32px',
        borderRadius: '50px',
        margin: '10px'
    },
    select: {
        "&:after": {
          borderBottomColor: "#545b62",
        },
        "& .MuiSvgIcon-root": {
          color: "#545b62",
        },
      },
      label: {
        color: "#545b62",
        "&.Mui-focused": {
          color: "#545b62",
        },
      },
}));

const SelectInput = withStyles((theme) => ({
    root: {
      'label + &': {
        color: '#fff',
      },
    },
    input: {
      borderRadius: 4,
      backgroundColor: '#545b62',
      border: '1px solid #4e555b',
      fontSize: 13,
      width: 80,
      paddingLeft: 10,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#4e555b',
        backgroundColor: '#545b62',
        boxShadow: '0 0 0 0.2rem rgb(130 138 145 / 50%)',
      },
    },
  }))(InputBase);

function Resources() {
    const params = window.location.search;
    const urlParams = new URLSearchParams(params.substring(1));
    var tokenParam = urlParams.get("token");
    var token;
    if(tokenParam != undefined) {
        token = tokenParam.replace(/ /g, "+");
    }
    const bucket = urlParams.get("bucket");
    const prefix = urlParams.get("prefix");

    const classes = useStyles();

    const [resources, setResources] = useState(undefined);
    const [originalResources, setOriginalResources] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [selectedRow, setSelectedRow] = useState();
    const [searched, setSearched] = useState("");
    const history = useHistory();
    const [pageSize, setPageSize] = useState(10);

    const [searching, setSearching] = useState(false);

    useEffect(() => {
        setTimeout(()=> {
            getResources(token, prefix, bucket).then(data => {
                setResources(data);
                setOriginalResources(data);
            }).catch(err => {
                console.error(err);
                history.push("/error");
            });
    }, 3000);
    }, []);

    const handleSearchChange = (params) => {
        let searchVal = params.target.value;
        setSearched(searchVal);
        handleSearch(searchVal);
    }

    const handleSearch = (searchVal) => {
        if (searchVal.length >= 3) {
            setSearching(true);
            setSearched(searchVal);
            let searchString = searchVal.toLowerCase();
            setResources(originalResources.filter(r => (r.Title && r.Title.toLowerCase().indexOf(searchString) > -1) || r.Key.toLowerCase().indexOf(searchString) > -1));
        }
        else if (searchVal.length == 0) {
            setSearching(false);
            setSearched("");
            setResources(originalResources);
        }
    }

    const cancelSearch = () => {
        setSearching(false);
        setSearched("");
        handleSearch("");
      };

    const handleSort = (event) => {
        let orderedRows = [];
        switch (event.target.value) {
            case 'title':
                orderedRows = _.orderBy(resources, ['Title'], ['asc']);
                break;
            case 'fileType':
                orderedRows = _.orderBy(resources, ['FileType'], ['asc']);
                break;
            case 'date':
                orderedRows = _.orderBy(resources, ['Date'], ['desc']);
                break;
        }

        setResources(orderedRows);
    }

    const handleClickOpen = (row) => {
        setSelectedRow(row);
        setOpen(true);
    };

    const handleClose = () => {
        setSelectedRow();
        setOpen(false);
    };

    const handleDelete = () => {
        try {
            deleteResource(token, bucket, prefix, selectedRow);
            const filteredRows = _.orderBy(resources.filter((row) => {
                return row.Key.toLowerCase() != selectedRow.Key.toLowerCase();
            }));
            setResources(filteredRows);
        } catch (error) {
            console.error(error);
        }
        setOpen(false);
    }

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        //alert(rowsPerPage);
        setPage(0);
    };

    const truncateDescription = (desc) => {
        if (!desc){
            return "No Description";
        }
        if (desc.length > 200) {
            return desc.substring(0, 200) + "...";
        }
        return desc;
    }

    const columns = [
        { 
            field: 'Title', 
            headerName: 'Title',
            type: 'string',
            flex: 1.5,
            headerClassName: 'resource-column-header',
            renderCell: (params) => (<div align="left" style={{ width: '30%', fontWeight: 'bold' }}><a target='blank' style={{ textDecoration: 'none' }} href={params.row.Url}>{params.row.Title || params.row.Key}</a></div>)
        },
        { 
            field: 'Tags', 
            headerName: 'Categories',
            type: 'string',
            headerClassName: 'resource-column-header',
            flex: 0.5
            // width: '30%'
        },
        { 
            field: 'FileType', 
            headerName: 'File Type',
            type: 'string',
            headerClassName: 'resource-column-header',
            flex: 0.5
            // width: '30%'
    
        },
        { 
            field: 'Date', 
            headerName: 'Date',
            type: 'string',
            headerClassName: 'resource-column-header',
            flex: 1,
            // width: '30%',
            valueGetter: (params) => (moment(params.row.Date).format("MMM Do YY")),
    
        },
        {
            headerName: 'Actions',
            field: 'action',
            sortable: false,
            headerClassName: 'resource-column-header',
            flex: 0.5,
            renderCell: (params) => (<Button variant="contained" color="error" onClick={() => handleClickOpen(params.row)}>Delete</Button>)
        }
    ];

    return (
        <div className="App container">
            { resources !== undefined || searched !== "" ?
                <div>
                    <Grid xs={12}>
                        <Grid container spacing={2} direction="row" justify="flex-start" alignItems="center" style={{marginLeft:20}}>
                            <Grid item className={classes.marginButton}>
                                <NewResourceButton className={classes.newButton} component={Link} to={{
                                    pathname: "/addResource",
                                    state: {token: token, bucket: bucket, prefix: prefix }
                                }} variant="contained" color="primary">
                                    Add new Resource
                                </NewResourceButton>
                            </Grid>
                            {/* <Grid item>
                                <FormControl variant="outlined">
                                    <InputLabel id="demo-simple-select-outlined-label" style={{marginTop:-10, color: 'white'}}>Sort By</InputLabel>
                                    <Select
                                        onChange={handleSort}
                                        label="Sort By"
                                        input={<SelectInput />}
                                    >
                                        <MenuItem value="title">Title</MenuItem>
                                        <MenuItem value="fileType">File Type</MenuItem>
                                        <MenuItem value="date">Date</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid> */}
                            <Grid item className={classes.marginButton}>
                            <OutlinedInput
                                className={classes.searchInput}
                                id="search"
                                type="text"
                                value={searched}
                                onChange={(searchVal) => handleSearchChange(searchVal)}
                                InputLabelProps={{shrink: false}}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="search"
                                    // onClick={handleSearch(searched)}
                                    edge="end"
                                    >
                                    <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                                }
                                placeholder="Search"
                                label=""
                            />
                            </Grid>
                        </Grid>
                    </Grid>
                    <div></div>
                    <DataGrid
                        getRowId={(row) => row.Key}
                        columns={columns}
                        autoHeight
                        rows={resources}
                        pageSize={pageSize}
                        initialState={{
                            sorting: {
                              sortModel: [{ field: 'Date', sort: 'desc' }],
                            },
                          }}
                        rowsPerPageOptions={[10, 20 ,50]}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        disableSelectionOnClick
                    />
                    <div>
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Delete the resource?"}</DialogTitle>
                            <DialogActions>
                                <Button onClick={handleDelete} color="primary">
                                    Yes
          </Button>
                                <Button onClick={handleClose} color="primary" autoFocus>
                                    No
          </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </div>
                : <ContentLoader />
            }
        </div>
    );
}

export default Resources;
